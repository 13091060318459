






























































import useAccessory from "@/use/accessory";
import { defineComponent, reactive, ref } from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    MInventoryUserAssignmentsVehicles: () =>
      import(
        "@/components/molecules/m-inventory-user-assignments-vehicles.vue"
      ),
    MInventoryUserAssignmentsAccessories: () =>
      import(
        "@/components/molecules/m-inventory-user-assignments-accessories.vue"
      ),
  },
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
    company: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props, { root }) {
    const { getAccessoryIcon } = useAccessory();

    const refs = {
      vehicles: ref(),
      accessories: ref(),
    };

    const items = reactive({
      user: null,
      vehicles: [],
      accessories: [],
      total: {
        vehicles: 0,
        accessories: 0,
      },
    });

    const state = reactive({ loading: false, loaded: false });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      axiosInstance
        .get(`inventory/user/${props.id}`, {
          params: {
            company: props.company || undefined,
          },
        })
        .then(
          ({
            data: {
              user,
              vehicles,
              accessories,
              totalVehicles,
              totalAccessories,
            },
          }) => {
            items.user = user;
            items.vehicles = vehicles;
            items.accessories = accessories;
            items.total.vehicles = totalVehicles;
            items.total.accessories = totalAccessories;

            state.loaded = true;
          }
        )
        .catch(() => {
          items.user = null;
          items.vehicles = [];
          items.accessories = [];
          items.total.vehicles = 0;
          items.total.accessories = 0;

          state.loaded = false;
        })
        .finally(() => (state.loading = false));
    };

    watchDebounced(() => [props.id, props.company], fetch, {
      deep: true,
      immediate: true,
      debounce: 500,
      maxWait: 5000,
    });

    const refresh = () => {
      fetch();
      Object.values(refs).forEach((item) => {
        if (item.value && "refresh" in item.value) item.value.refresh();
      });
    };

    return { getAccessoryIcon, items, state, refresh, ...refs };
  },
});
